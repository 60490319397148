const CONTACT_API = {	
	getNews:{
		url:'resources/listResource.do',
		method:'get'
	},
	getNewItemList:{
		url:'new/newItemList',
		method:'get'
	},
	getNewList:{
		url:'new/homeNewList',
		method:'get'
	},
	getNewsList:{
		url:'new/newList',
		method:'get'
	},
	getNewById:{
		url:'new/newById',
		method:'get'
	}
}
export default CONTACT_API;
import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
const originalPush = Router.prototype.push 
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default new Router({
	mode:'hash',
	
	routes: [		
		{
			path:'/index',
			component:()=>import(/* webpackChunkName: "index" */ '../pages/index.vue'),
			meta:{title:'首页'},
		},
		{
			path:'/solution',
			component:()=>import(/* webpackChunkName: "solution" */ '../pages/solution.vue'),
			meta:{title:'解决方案'},
			children:[
				{
					path:'/solution/basic_data',
					component:()=>import(/* webpackChunkName: "basic_data" */ '../pages/solution_basic_data.vue'),
					meta:{title:'基础数据处理服务中心'},
				},
				{
					path:'/solution/data_apply',
					component:()=>import(/* webpackChunkName: "data_apply" */ '../pages/solution_data_apply.vue'),
					meta:{title:'数据应用研发服务中心'},
				},
				{
					path:'/solution/business_scenes',
					component:()=>import(/* webpackChunkName: "business_scenes" */ '../pages/solution_business_scenes.vue'),
					meta:{title:'业务场景综合应用中心'},
				},
				{
					path:'/solution/cloud',
					component:()=>import(/* webpackChunkName: "cloud" */ '../pages/solution_cloud.vue'),
					meta:{title:'智慧云服务中心'},
				},
				{
					path:'/',
					redirect:'/solution/basic_data'
				}
			]
		},
		{
			path:'/news',
			component:()=>import(/* webpackChunkName: "news" */ '../pages/news.vue'),
			meta:{title:'新闻'},
			// children:[
			// 	{
			// 		path:'/news/detail',
			// 		component:()=>import(/* webpackChunkName: "intro" */ '../pages/news_detail.vue'),
			// 		meta:{title:'新闻详情'},
			// 	},
			// ]
		},
		{
			path:'/news/detail',
			component:()=>import(/* webpackChunkName: "intro" */ '../pages/newsdetail.vue'),
			meta:{title:'新闻详情'},
			
		},
		{
			path:'/about',
			component:()=>import(/* webpackChunkName: "contact" */ '../pages/about.vue'),
			meta:{title:'关于我们'},
			children:[
				{
					path:'/about/intro',
					component:()=>import(/* webpackChunkName: "intro" */ '../pages/about_intro.vue'),
					meta:{title:'公司简介'},
				},
				{
					path:'/about/honor',
					component:()=>import(/* webpackChunkName: "honor" */ '../pages/about_honor.vue'),
					meta:{title:'荣誉资质'},
				},
				{
					path:'/about/client',
					component:()=>import(/* webpackChunkName: "client" */ '../pages/about_client.vue'),
					meta:{title:'典型客户'},
				},
				{
					path:'/about/case',
					component:()=>import(/* webpackChunkName: "case" */ '../pages/about_case.vue'),
					meta:{title:'典型案例'},
				},
				{
					path:'/about/join',
					component:()=>import(/* webpackChunkName: "join" */ '../pages/about_join.vue'),
					meta:{title:'加入我们'},
				},
				{
					path:'/about/cjrh',
					component:()=>import(/* webpackChunkName: "join" */ '../pages/about_cjrh.vue'),
					meta:{title:'产教融合'},
				},
				{
					path:'/',
					redirect:'/about/intro'
				}
			]
		},
		{
			path:'/',
			redirect:'/index'
		}
	],
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return {x:0,y:0}
		}
	  },
});
import Vue from 'vue'
import App from './App.vue'
import router from './router/router';
import Antd from 'ant-design-vue';
import Http from './until/http';
import 'ant-design-vue/dist/antd.css';
import './scss/base.scss';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);
//图片懒加载
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

Vue.config.productionTip = false
Vue.prototype.$http=Http;
Vue.use(Antd);
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')

<template>
  <div class="header">
    <div class="navbox">
      <div class="logo fl">
        <router-link to="/index?side=1"
          ><img src="../assets/logo.png" alt="" @click="active1"
        /></router-link>
        <!-- <a href=""><img src="../assets/logo.png" alt=""
        /></a> -->
      </div>
      <ul class="nav fr">
        <li
          class="home"
          @click="topSide(1)"
          :class="sideActive == 1 ? 'sideActive' : ''"
        >
          <router-link to="/index?side=1">首页</router-link>
        </li>
        <div class="div"></div>
        <li
          class="scheme"
          @click="topSide(2)"
          :class="sideActive == 2 ? 'sideActive' : ''"
        >
          <router-link to="/solution?side=2">解决方案</router-link>
          <transition
            enter-active-class="animate__fadeIn"
            leave-active-class="animate__fadeOut"
          >
            <div class="schemeDowe animate__animated" v-show="schemeShow">
              <div class="jiao1"></div>
              <div class="schemeTit">解决方案</div>
              <ul>
                <li
                  @click="tabCli(1)"
                  :class="tabActive == 1 ? 'tabActive' : ''"
                >
                  <router-link to="/solution/basic_data?tab=1"
                    >基础数据处理服务中心</router-link
                  >
                </li>
                <li
                  @click="tabCli(2)"
                  :class="tabActive == 2 ? 'tabActive' : ''"
                >
                  <router-link to="/solution/data_apply?tab=2"
                    >数据应用研发服务中心</router-link
                  >
                </li>
                <li
                  @click="tabCli(3)"
                  :class="tabActive == 3 ? 'tabActive' : ''"
                >
                  <router-link to="/solution/business_scenes?tab=3"
                    >业务场景综合应用中心</router-link
                  >
                </li>
                <li
                  @click="tabCli(4)"
                  :class="tabActive == 4 ? 'tabActive' : ''"
                >
                  <router-link to="/solution/cloud?tab=4"
                    >智慧云服务中心</router-link
                  >
                </li>
              </ul>
            </div>
          </transition>
        </li>
        <li
          class="newCore"
          @click="topSide(3)"
          :class="sideActive == 3 ? 'sideActive' : ''"
        >
          <router-link to="/news?side=3">新闻中心</router-link>
        </li>
        <li
          class="about"
          @click="topSide(4)"
          :class="sideActive == 4 ? 'sideActive' : ''"
        >
          <router-link to="/about?side=4">关于我们</router-link>
          <transition
            enter-active-class="animate__fadeIn"
            leave-active-class="animate__fadeOut"
          >
            <div class="aboutDowe animate__animated" v-show="aboutShow">
              <div class="jiao1"></div>
              <div class="aboutTit">关于我们</div>
              <ul>
                <li
                  @click="aboutCli(1)"
                  :class="tabAboutActive == 1 ? 'tabAboutActive' : ''"
                >
                  <router-link to="/about/intro?tab=1">公司简介</router-link>
                </li>
                <li
                  @click="aboutCli(2)"
                  :class="tabAboutActive == 2 ? 'tabAboutActive' : ''"
                >
                  <router-link to="/about/honor?tab=2">荣誉资质</router-link>
                </li>
                <li
                  @click="aboutCli(3)"
                  :class="tabAboutActive == 3 ? 'tabAboutActive' : ''"
                >
                  <router-link to="/about/client?tab=3">典型客户</router-link>
                </li>
                <li
                  @click="aboutCli(4)"
                  :class="tabAboutActive == 4 ? 'tabAboutActive' : ''"
                >
                  <router-link to="/about/case?tab=4">典型案例</router-link>
                </li>
                <li
                  @click="aboutCli(5)"
                  :class="tabAboutActive == 5 ? 'tabAboutActive' : ''"
                >
                  <router-link to="/about/join?tab=5">加入我们</router-link>
                </li>
                <li
                  @click="aboutCli(6)"
                  :class="tabAboutActive == 6 ? 'tabAboutActive' : ''"
                >
                  <router-link to="/about/cjrh?tab=6">产教融合</router-link>
                </li>
              </ul>
            </div>
          </transition>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import "animate.css";
export default {
  data() {
    return {
      schemeShow: false,
      aboutShow: false,
      sideActive: 1,
      tabActive: 1,
      bgIsShow: true,
      tabAboutActive: 1,
    };
  },
  watch: {
    $route: {
      immediate: true, // 一旦监听到路由的变化立即执行
      handler(to) {
        //解决刷新高亮丢失问题
        var cm = to.path.slice(0, 6);
        if (cm === "/index") {
          this.sideActive = 1;
           this.aboutShow = false;
           this.schemeShow = false;
        } else if (cm === "/solut") {
          this.sideActive = 2;
           this.schemeShow = true;
            this.aboutShow = false;
        } else if (cm === "/news"||cm==="/news/") {
          this.sideActive = 3;
           this.aboutShow = false;
           this.schemeShow = false;
        } else if (cm === "/about") {
          this.sideActive = 4;
           this.aboutShow = true;
            this.schemeShow = false;
        }

        // 解决方案页面tab和关于我们页面tab的高亮
        if (to.path == "/solution/data_apply") {
          this.tabActive = 2;
        } else if (to.path == "/solution/business_scenes") {
          this.tabActive = 3;
        } else if (to.path == "/solution/cloud") {
          this.tabActive = 4;
        } else {
          this.tabActive = 1;
        }
        if (to.path == "/about/join") {
          this.tabAboutActive = 5;
        } else if (to.path == "/about/honor") {
          this.tabAboutActive = 2;
        } else if (to.path == "/about/client") {
          this.tabAboutActive = 3;
        } else if (to.path == "/about/case") {
          this.tabAboutActive = 4;
        } else {
          this.tabAboutActive = 1;
        }
      },
    },
  },
  methods: {
    //点击logo高亮首页按钮
    active1() {
      this.sideActive = 1;
    },
    //控制解决方案关于我们下方气泡
    topSide(id) {
      this.sideActive = id;
      // if (id == 2) {
      //   this.schemeShow = true;
      // } else {
      //   this.schemeShow = false;
      // }
      // if (id == 4) {
      //   this.aboutShow = true;
      // } else {
      //   this.aboutShow = false;
      // }
    },
    tabCli(id) {
      this.tabActive = id;
    },
    aboutCli(id) {
      this.tabAboutActive = id;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
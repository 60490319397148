import axios from 'axios'
import service from './contactApi';
import config from './configs';
import { messageTips } from './tools';
import qs from 'qs'
// service 循环遍历输出不同的请求方法
let instance = axios.create({
    baseURL:config.baseUrl,
    timeout:600000
})
const Http = {}; // 包裹请求方法的容器

// 请求格式/参数的统一
for(let key in service){
    let api = service[key]; // url method
    // async 作用：避免进入回调地狱
    Http[key] = async function(
        params, // 请求参数 get：url，put，post，patch（data），delete：url
        isFormData=false,// 标识是否是form-data请求
        configParmas={} // 配置参数
    ){
        let newParams = {}
        //  content-type是否是form-data的判断
        if(params && isFormData){
            instance.interceptors.request.use(
                config => {
                  config.data = qs.stringify(config.data) // 转为formdata数据格式
                  return config
                },
                error => Promise.error(error)
              )
        }else{
            newParams = params
        }

        // 不同请求的判断
        let response = {}; // 请求的返回值
        if(api.method === 'post'){
            try{                
                if(api.noTrans) {
                    var postParams = new URLSearchParams();
                    for(let i in newParams) {
                        postParams.append(i,newParams[i]);
                    }
                    response =  await instance[api.method](api.url,postParams,configParmas)
                } else {
                    response =  await instance[api.method](api.url,newParams,configParmas)
                }
            }catch(err){
                response = err
            }
        }else if(api.method === 'delete'|| api.method === 'get'){
            configParmas.params = newParams
            try{
                response =  await instance[api.method](api.url,configParmas)
            }catch(err){
                response = err
            }
        }
        return response; // 返回响应值
    }
}

// 拦截器的添加
// 请求拦截器
instance.interceptors.request.use(config=>{
    // 发起请求前做些什么
    
    return config
},()=>{
    // 请求错误
    
})
// 响应拦截器
instance.interceptors.response.use(res=>{
    // 请求成功 
    return res.data
},(error)=>{
    var tips;
    if(error&&error.response) {
        switch(error.response.status){
            case 400:
                    tips = '错误请求-'+error.response.status;
                break;
            case 401:
                    tips = '未授权，请重新登录-'+error.response.status;
                break;
            case 403:
                    tips = '拒绝访问-'+error.response.status;
                break;
            case 404:
                    tips = '错误请求，未找到该资源-'+error.response.status;
                break;
            case 405:
                    tips = '请求方法未允许-'+error.response.status;
                break;
            case 408:
                    tips = '请求超时-'+error.response.status;
                break;
            case 500:
                    tips = '服务器端出错-'+error.response.status;
                break;
            case 501:
                    tips = '网络未实现-'+error.response.status;
                break;
            case 502:
                    tips = '网络错误-'+error.response.status;
                break;
            case 503:
                    tips = '服务不可用-'+error.response.status;
                break;
            case 504:
                    tips = '网络超时-'+error.response.status;
                break;
            case 505:
                    tips = 'http版本不支持该请求-'+error.response.status;
                break;
            default:
                tips = `连接错误${error.response.status}`;
                break;
        }
    } else{
        tips = '网络出现问题，请稍候重试';
    }
    
    if(error.response.status===401) {
        messageTips(error.response.data.message,1);
    } else {
        messageTips(tips,1);
    }
})

export default Http
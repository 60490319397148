import {message} from 'ant-design-vue';
export function messageTips(tips,type,time) {    
    message.config({top: '48%',});
    switch(type){
        case 1:    
            message.error({
                duration: time||2,
                maxCount: 1,
                key:type,
                icon:(h)=>{
                    h();
                    return <a-icon type="close-circle" color="#eb5a33" />;
                },
                content:tips,
                onClose:()=>{
                    message.destroy();
                }
            });
        break;
        case 2:
            message.warning({
                duration: time||2,
                maxCount: 1,
                key:type,
                icon:(h)=>{
                    h();
                    return <a-icon type="exclamation-circle" color="#eb5a33" />;
                },
                content:tips,
                onClose:()=>{
                    message.destroy();
                }
            });  
        break;
        case 3:
            message.success({
                duration: time||2,
                maxCount: 1,
                key:type,
                icon:(h)=>{
                    h();
                    return <a-icon type="check-circle" color="#52c41a" />;
                },
                content:tips,
                onClose:()=>{
                    message.destroy();
                }
            });   
        break;
        default:break;
    }
}
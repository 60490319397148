<template>
  <a-config-provider :locale="zh_CN">
    <div id="app">
      <Header />
      <div class="main-content">
        <router-view> </router-view>
      </div>
      <Footer />
    </div>
  </a-config-provider>
</template>

<script>
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
import "moment/locale/zh-cn";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
moment.locale("zh-cn");
export default {
  name: "App",
  components: { Header, Footer },
  data() {
    return {
      zh_CN,
    };
  },
};
</script>

<style>
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box; /* Firefox */
  -webkit-box-sizing: border-box; /* Safari */
}
#app {
  height: 100%;
  /* overflow-y: auto; */
}
#app::-webkit-scrollbar {
  display: none;
}
</style>